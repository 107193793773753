import { Pricing as PricingNarrow } from './PricingNarrow'
import { Pricing as PricingWide } from './PricingWide'

interface PricingProps {
  isLargeAndUp: boolean
  plans: any[]
  options?: any
}
export const Pricing = React.memo(({ isLargeAndUp, plans, options = {} }: PricingProps) =>
  React.createElement(isLargeAndUp ? PricingWide : PricingNarrow, { plans, options })
)
