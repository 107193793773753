import { CommonStyles } from './styles'
import { storage } from 'src/storage'
import { setReturnUrl } from 'src/utils/setReturnUrl'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { featureFlags } from 'src/providers/Split/utils'
import { Icon } from 'src/components/Icon'

const PricingDevices = () => {
  return (
    <PricingStyles.Container>
      <Icon.IPhone />
      <Icon.IPad />
      <Icon.Laptop />
      <Icon.Desktop />
    </PricingStyles.Container>
  )
}

interface PricingTitleProps {
  title?: string
  style?: any
}
const PricingTitle = ({ title, style }: PricingTitleProps) => {
  const { treatment: landingPageRedesign2023 } = useAnonymousTreatment(
    featureFlags.landingPageRedesign2023
  )

  if (landingPageRedesign2023 === 'variant_a_lp') {
    return <CommonStyles.Title style={style}>Choose your plan</CommonStyles.Title>
  }

  return <CommonStyles.Title style={style}>{title || 'Start your free trial'}</CommonStyles.Title>
}

const PricingDek = ({ style }: { style: any }) => {
  const { treatment: landingPageRedesign2023 } = useAnonymousTreatment(
    featureFlags.landingPageRedesign2023
  )

  if (landingPageRedesign2023 === 'variant_a_lp') {
    return ''
  }

  return (
    <Dek style={style}>
      One subscription for everything you need. Cancel anytime. No charge until the trial ends. Only
      available in US and Canada.
    </Dek>
  )
}

interface PricingButtonProps {
  label: string
  buttonStyling: any
  uiStore?: any
  id: string
  Button: any
}
const PricingButton = ({ label, buttonStyling, uiStore, id, Button }: PricingButtonProps) => {
  const { treatment: landingPageRedesign2023 } = useAnonymousTreatment(
    featureFlags.landingPageRedesign2023
  )

  let cta = 'Start free trial'
  if (landingPageRedesign2023 === 'variant_a_lp') {
    cta = 'Get started'
  }

  return (
    <Button
      action={`Clicked SFT-MP-Subscription-${label}`}
      buttonStyling={buttonStyling}
      onClick={() => {
        obe.analytics.track('marketingModulesViewedOnSFT', uiStore?.marketingModulesViewed)
        storage.session.setItem('selectedPlan', id)
        setReturnUrl(/^\/videos\//)
      }}
      synthetic={false}
      to={`/users/sign_up${window.location.search}`}
    >
      {cta}
    </Button>
  )
}

const Dek = styled(CommonStyles.Blurb)`
  text-align: center;
`

const PricingStyles = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 24px;
  `,
}

export { PricingDevices, PricingTitle, PricingDek, PricingButton }
