import { useInView } from 'react-intersection-observer'

export const InViewContainer = ({ className, children, offset = 200 }) => {
  const [ref, inView] = useInView({
    rootMargin: `${offset}px 0px 0px 0px`,
    triggerOnce: true,
  })

  return React.createElement('div', { ref, className }, inView && children)
}
