import { breakpoint, button } from 'src/styles'
import { Container } from 'src/components/Display/Container'
import { getDefaultPlan } from 'src/models/plan'
import { InputPlanSelector } from 'src/components/InputPlanSelector'
import { Link } from 'src/components/Link'
import { useUiState } from 'src/hooks/useStores'
import { PricingDevices, PricingTitle, PricingDek, PricingButton } from './PricingElements'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { featureFlags } from 'src/providers/Split/utils'

export const Pricing = React.memo(({ plans, options }) => {
  const { treatment: landingPageRedesign2023 } = useAnonymousTreatment(
    featureFlags.landingPageRedesign2023
  )

  const [selectedPlan, setSelectedPlan] = React.useState(getDefaultPlan(plans))

  const currentPlan = plans.filter(({ planIdentifier }) => planIdentifier === selectedPlan)?.[0]
  const showDevices = landingPageRedesign2023 === 'variant_a_lp'

  const { uiStore } = useUiState()

  return (
    <React.Fragment>
      <Styles.Container>
        {showDevices && <PricingDevices />}
        <PricingTitle style={{ marginBottom: '16px', textAlign: 'center' }} title={options.title} />
        <PricingDek style={{ marginBottom: '50px' }} />

        <Styles.PlansContainer>
          {selectedPlan &&
            React.createElement(InputPlanSelector, {
              plan: selectedPlan?.planIdentifier,
              plans,
              setPlan: setSelectedPlan,
            })}
          <Styles.Footer>
            {!!currentPlan?.labels?.perks && (
              <Styles.PerksContainer>
                {currentPlan?.labels?.perks(currentPlan?.savings)}
              </Styles.PerksContainer>
            )}
          </Styles.Footer>
        </Styles.PlansContainer>
        <PricingButton
          label={currentPlan?.labels?.term}
          uiStore={uiStore}
          id={selectedPlan}
          Button={Styles.Button}
        />
      </Styles.Container>
    </React.Fragment>
  )
})

const Styles = {
  Container: styled(Container)`
    padding: 0px 20px;
  `,
  PlansContainer: styled.div`
    padding-bottom: 0;
    margin-bottom: 40px;

    ${breakpoint.medium} {
      padding-bottom: 2rem;
    }
  `,
  Footer: styled.div`
    margin: 25px 20px;
    text-align: center;
    b {
      font-weight: 600;
    }
  `,
  PerksContainer: styled.div`
    ul {
      list-style-type: none;
      padding-left: 0px;
    }
  `,
  Button: styled(Link)`
    ${button.newPrimary}
  `,
}
