import { button } from 'src/styles'
import { Container } from 'src/components/Display/Container'
import { Flex } from 'src/components/Display/Flex'
import { Link } from 'src/components/Link'
import { useUiState } from 'src/hooks/useStores'
import { PricingDevices, PricingTitle, PricingDek, PricingButton } from './PricingElements'
import { useAnonymousTreatment } from 'src/providers/Split/hooks/useAnonymousTreatment'
import { featureFlags } from 'src/providers/Split/utils'

export const Pricing = React.memo(({ plans, options }) => {
  const { uiStore } = useUiState()
  const { treatment: landingPageRedesign2023 } = useAnonymousTreatment(
    featureFlags.landingPageRedesign2023
  )

  const showDevices = landingPageRedesign2023 === 'variant_a_lp'

  return (
    <React.Fragment>
      <Container>
        {showDevices && <PricingDevices />}
        <PricingTitle style={{ paddingBottom: 24, textAlign: 'center' }} title={options.title} />
        <PricingDek style={{ marginBottom: '40px' }} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Flex.Row gutter={0} style={{ maxWidth: 1024, justifyContent: 'center' }}>
            <Flex.Cell width='10%'>
              <Styles.Label style={{ paddingBottom: 64, paddingTop: 52 }}>Plan</Styles.Label>
              <Styles.Label>Perks</Styles.Label>
            </Flex.Cell>
            {plans?.map(({ planIdentifier, labels }) => (
              <Flex.Cell width='45%' key={planIdentifier}>
                <Styles.Option backgroundColor={options.backgroundColor}>
                  <div>
                    <Styles.PlanNameContainer>
                      <Styles.Term>{labels?.term}</Styles.Term>
                    </Styles.PlanNameContainer>
                    {!!labels?.perksList && <Styles.Perks>{labels?.perksList()}</Styles.Perks>}
                  </div>
                  <PricingButton
                    label={labels?.term}
                    buttonStyling={options.buttonStyling || 'newPrimary'}
                    uiStore={uiStore}
                    id={planIdentifier}
                    Button={Styles.Button}
                  />
                </Styles.Option>
              </Flex.Cell>
            ))}
          </Flex.Row>
        </div>
      </Container>
    </React.Fragment>
  )
})

const Styles = {
  Label: styled.div`
    color: var(--neutrals700);
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    padding-right: 12px;
  `,
  Option: styled.div`
    position: relative;
    border-left: 1px solid var(--neutrals600);
    height: 100%;
    padding: 40px 40px;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ isBestValue = false, backgroundColor }) =>
      isBestValue &&
      css`
        background-color: ${backgroundColor || 'rgba(253, 246, 130, 0.33)'};
        :: after {
          background-color: var(--yellow);
          content: 'BEST VALUE';
          font-size: 20px;
          left: 0;
          line-height: 20px;
          padding: 16px;
          position: absolute;
          top: -52px;
        }
      `}
  `,
  Term: styled.div`
    font-weight: 250;
    font-size: 36px;
    line-height: 52px;
    padding-bottom: 48px;
  `,
  Price: styled.div`
    display: flex;
    padding-bottom: 8px;
  `,
  PriceUnits: styled.div`
    font-size: 38px;
    line-height: 38px;
    padding-top: 8px;
  `,
  PriceAmount: styled.div`
    font-size: 73px;
    line-height: 73px;

    ${({ isLevelUp }) =>
      !isLevelUp &&
      css`
       margin-bottom: 73px;
      }
    `}
  `,
  PriceLabel: styled.div`
    font-size: 18px;
    line-height: 18px;
    padding-top: 28px;
    padding-left: 4px;
  `,
  MonthlyPrice: styled.div`
    font-size: 18px;
    line-height: 1.45;
    margin-bottom: 52px;
  `,
  Perks: styled.div`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    b {
      font-weight: 600;
    }
    ul {
      padding-left: 20px;
    }
  `,
  Button: styled(Link)`
    ${({ buttonStyling }) => css`
      ${button[buttonStyling]}
    `}
    width: fit-content;
  `,
  PlanNameContainer: styled.div`
    display: flex;
  `,
  Savings: styled.div`
    margin-bottom: 56px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #e5e5e5;
    color: #5d5d5d;
    width: 158px;
    text-align: center;

    ${({ isQuarterly }) =>
      isQuarterly &&
      css`
       width: 80%;
      }
    `}
    ${({ isLevelUp }) =>
      isLevelUp &&
      css`
       padding-left: 8px;
      }
    `}
  `,
}
