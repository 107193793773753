import { breakpoint } from 'src/styles'
import { Icon } from 'src/components/Icon'
import { formatCents, formatSavings } from 'src/utils/formatCents'
import { UserAction } from 'src/components/UserAction'

export const InputPlanSelector = ({
  isChangingPlans = false,
  plan,
  monthlyPlanCost,
  plans = [],
  setPlan = Function.prototype,
  showPrices = true,
  personalTrainingPlanNote = null,
  formatCentsDecimals = 2,
}) => {
  return (
    <Styles.Container>
      {plans.map(({ labels, periodPriceCents, planIdentifier, periodMonthCount }) => (
        <div key={planIdentifier}>
          <Styles.Option
            action={`Clicked ${labels?.term} Subscription`}
            isSelected={plan === planIdentifier}
            onClick={() =>
              isChangingPlans
                ? setPlan(planIdentifier)
                : setPlan(plans.find((plan) => plan.planIdentifier === planIdentifier))
            }
          >
            {periodMonthCount === 12 && (
              <Styles.Savings isAnnualSelected={plan?.includes('yearly')}>
                Save {formatSavings(plans, monthlyPlanCost).toFixed(0)}%
              </Styles.Savings>
            )}

            <Styles.PillContent isSelected={plan === planIdentifier}>
              {plan === planIdentifier && (
                <Styles.Ellipse>
                  <Styles.Checkmark />
                </Styles.Ellipse>
              )}

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Styles.Term>
                  {labels?.term} · $
                  {(
                    formatCents(periodPriceCents, formatCentsDecimals, false) / periodMonthCount
                  ).toFixed(formatCentsDecimals)}
                  /month
                </Styles.Term>
                <Styles.Pricing>
                  {showPrices && (
                    <span>
                      Billed&nbsp;
                      {periodMonthCount === 1
                        ? 'every month'
                        : `${labels?.adjective} at ${formatCents(periodPriceCents)}`}
                    </span>
                  )}
                  {personalTrainingPlanNote && (
                    <Styles.PlanNotes>{personalTrainingPlanNote}</Styles.PlanNotes>
                  )}
                </Styles.Pricing>
              </div>
            </Styles.PillContent>
          </Styles.Option>
        </div>
      ))}
    </Styles.Container>
  )
}
const Styles = {
  Container: styled.div`
    > div + div {
      margin-top: 20px;
    }
  `,
  Option: styled(UserAction)`
    border: 3px solid var(--grey);
    border-radius: 120px;
    padding: 16px 36px;
    position: relative;
    margin-bottom: 15px;
    position: relative;

    :focus {
      outline: 0;
    }
    ${breakpoint.smallAndDown} {
      padding: 16px 24px;
    }

    ${({ isSelected }) =>
      isSelected &&
      css`
        border: 2px solid var(--persianBlue) !important;
        // Lower padding by 1px to compensate for border increase
        padding: 15px 35px;

        ${breakpoint.smallAndDown} {
          padding: 15px 23px;
        }

        :focus {
          outline: 0;
          border: 2px solid var(--persianBlue) !important;
        }
      `}

    ${({ isSelected }) =>
      !isSelected &&
      css`
        border: 1px solid var(--neutrals600) !important;

        :focus {
          outline: 0;
          border-color: var(--mediumGrey);
        }
      `}
  `,
  Term: styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 145%;

    ${breakpoint.smallAndDown} {
      font-size: 18px;
    }

    font-size: 20px;
  `,
  Pricing: styled.div`
    display: flex;
    line-height: 145%;
    flex-wrap: wrap;
    font-size: 16px;
    padding-top: 2px;
    font-weight: 300;
  `,
  GiftCardText: styled.span`
    color: var(--persianBlue);
  `,
  Perks: styled.div`
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 30px;
    padding: 0 25px;
    text-align: center;
  `,
  Savings: styled.div`
    padding: 2px 8px;
    gap: 8px;
    text-align: center;
    position: absolute;
    height: 28px;
    top: -12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    border-radius: 8px;
    right: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    color: var(--white);
    background: #622aff;

    ${({ isAnnualSelected }) =>
      !isAnnualSelected &&
      css`
        background: #000000;
        right: 31px;
        top: -11px;
      `}
  `,
  Checkmark: styled(Icon.Checkmark)`
    left: 0px;
    top: 0px;
    path {
      stroke-width: 0px;
      stroke: white;
      fill: white;
    }
  `,
  Ellipse: styled.div`
    width: 24px;
    height: 24px;
    right: 25px;
    border: 1.5px solid var(--persianBlue);
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    top: 25px;
    align-items: center;
    justify-content: center;
    background-color: var(--persianBlue);
  `,
  PillContent: styled.div`
    align-items: center;
    ${({ isSelected }) =>
      isSelected &&
      css`
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      `}
  `,
  PlanNotes: styled.div`
    color: var(--feedback200);
    margin-left: 16px;
  `,
}
