import { breakpoint } from 'src/styles'

export const CommonStyles = {
  Title: styled.h2`
    font-weight: 250;
    font-size: 44px;
    line-height: 52px;
    color: #333333;
    letter-spacing: 0.005em;
    margin-bottom: 0;

    ${breakpoint.mediumAndDown} {
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.3px;
      line-height: 24px;
    }
  `,
  Blurb: styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;

    ${breakpoint.mediumAndDown} {
      font-size: 16px;
      line-height: 24px;
    }
  `,
}
